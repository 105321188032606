import React from 'react';
import logo from './logo.svg';
import './App.css';

interface Props {
}

interface State {
  resultText: string | undefined;
}

class App extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { 
      resultText: "Not yet set"
    };
  }

  setResult(text: string) {
    this.setState({resultText: text})
  }

  OnDoStuff1(){
    //alert("Clicked");
    console.log("Button 1 clicked");
    this.setResult("Clicked 1");
  
    fetch('https://swapi.dev/api/people/1')
     .then(response => {
      console.log("Got login result")
      console.dir(response);
      
      this.setResult("Got result 1: " + response.status);
      response.json().then(json => {
          console.dir(json);

          this.setResult("Got json 1");
      });

      
     });
  }
  
  OnDoStuff2()
  {
    if (! this){
      alert("No this");
      return;
    }

    console.dir(this);
    //alert("Clicked");
    console.log("Button 2 clicked");
    this.setResult("Clicked 2");
  
    fetch('/api/message')
     .then(response => {
      console.log("Got api result")
      console.dir(response);
      this.setResult("Got result 2: " + response.status);

      response.text().then(text => {
          console.dir(text);

          this.setResult(text);
      });

     });
  }

  render(): React.ReactNode {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Tols.net - v11 - result is <span>{this.state.resultText}</span>.
          </p>
          <p>
            <button onClick={() => this.OnDoStuff1()}>Do stuff 1</button>
          </p>
  
          <p>
            <button onClick={() => this.OnDoStuff2()}>Do stuff 2</button>
          </p>
        </header>
      </div>
    );
  }
}

export default App;
